<template>
  <div ref="dropdown" class="AccountDropdown border border-darkest bg-lightest min-w-224">
    <MyPageNavigationWrapper
      :type="userStore.isSalesRepUser ? 'salestool':'account'"
      selected=""
      :is-dropdown="true"
      @close="$emit('close')"
    />
  </div>
</template>

<script setup lang="ts">
import { onClickOutside } from '@vueuse/core';
import MyPageNavigationWrapper from '~/components/account/MyPageNavigationWrapper.vue';
import { useUserStore } from '~/store/user';
const userStore = useUserStore();

const emit = defineEmits<{
  (e: 'close'): void;
}>();

const dropdown = ref<HTMLElement>();

onClickOutside(dropdown, () => {
  setTimeout(()=> {
    emit('close');
  }, 100);
});

</script>

<style scoped lang="postcss">
</style>
