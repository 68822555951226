<template>
  <div
    v-if="boughtProduct && userPrice || cartStore.addedToCartCount > 0"
    class="AddedToCart fixed bottom-0 desk:bottom-auto desk:right-32 right-0 z-modalsContent bg-lightest w-full desk:w-[375px]"
    :class="{
      'desk:top-[85px]': isScrolled && scrollPos > 44,
      'desk:top-[161px]': !isScrolled || scrollPos <= 44,
    }"
  >
    <div
      class="shrink-0 grow-0 basis-56
             flex w-full items-center justify-between
             px-16 border-b border-light
             select-none py-16"
    >
      <div class="type-headline-2xl leading-single flex items-start">
        {{ $t('addedToCart.headline') }}
      </div>
      <button class="w-24 h-24 text-right cursor-pointer" @click="uiStore.setShowAddedToCart(false)">
        <img
          src="/icons/close.svg"
          class="h-24 w-24 inline-block"
          alt="Close"
        >
      </button>
    </div>
    <div class="p-16">
      <div v-if="cartStore.addedToCartCount > 1">
        <p>{{ $t('addedToCart.numProductsAdded', {num: cartStore.addedToCartCount}) }}</p>
      </div>
      <div v-else class="flex justify-between">
        <nuxt-link
          :to="productUrl(boughtProduct.url)"
          class="basis-64 h-80 shrink-0 ratio-product block relative flex items-center justify-center"
        >
          <div v-if="boughtProduct.image">
            <nuxt-img
              preset="standard"
              provider="norce"
              loading="lazy"
              sizes="sm:124px"
              :src="boughtProduct.image"
              class="w-48 h-48 object-cover"
            />
            <div class="imageOverlay absolute top-0 left-0 w-full h-full" />
          </div>
          <img
            v-else
            src="/icons/no-image.svg"
            class="aspect-product w-64 rounded-6"
            alt="no image"
          >
          <div v-if="userPrice?.price?.priceBeforeVat === '0'" class="absolute -top-6 -left-6 bg-lightest h-28 w-28 rounded-full flex items-center justify-center">
            <div class="h-24 w-24 rounded-full bg-brightGreen flex items-center justify-center">
              <img
                class="w-12 h-12"
                src="/icons/gift.svg"
              >
            </div>
          </div>
        </nuxt-link>
        <div
          class="w-full flex flex-col justify-between ml-16"
        >
          <div
            class="flex justify-between items-start"
          >
            <div class="type-xs-medium mb-8">
              <nuxt-link :to="productUrl(boughtProduct.url)">
                {{ boughtProduct.name }}
              </nuxt-link>
              <div class="type-xxs-medium text-dark">
                {{ boughtProduct.partNo }}
              </div>
            </div>
          </div>
          <div
            class="flex items-center"
          >
            <!-- bought with user price -->
            <div
              v-if="userPrice.status !== 'pending' && !cartStore.currentlyBuyingPriceListLocked"
              class="text-left"
            >
              <div
                class="type-xs-medium whitespace-nowrap"
                :class="{'text-brightRed': userPrice?.price?.isOnSale}"
              >
                {{ useLocalizedCurrency(priceByQuantity.price) }}
              </div>

              <div
                v-if="userPrice?.price?.isOnSale && priceByQuantity.priceBeforeDiscount > 0"
                class="type-xs-medium text-darker line-through whitespace-nowrap"
              >
                {{ useLocalizedCurrency(priceByQuantity.priceBeforeDiscount) }}
              </div>
            </div>
            <!-- bougth with standard price -->
            <div
              v-if="userPrice.status !== 'pending' && cartStore.currentlyBuyingPriceListLocked"
              class="text-left"
            >
              <div
                class="type-xs-medium whitespace-nowrap"
              >
                {{ useLocalizedCurrency(priceByQuantity.priceBeforeDiscount) }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <nuxt-link :to="$manualUrl('/checkout')" class="btn btn--lg w-full btn--confirm mt-16">
        {{ $t('minicart.toCheckout') }}
        <img
          src="/icons/arrow-right.svg"
          class="inline-block w-20 h-20 ml-12"
        >
      </nuxt-link>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useUiStore } from '~/store/ui';
import { useCartStore } from '~/store/cart';
import useLinkReplacer from '~/composeables/useLinkReplacer';
import { ProductRaw, ProductVariantOld } from '~/constants/types/algolia';
import { UserPrice, useUserStore } from '~/store/user';
import { useLocalizedCurrency } from '~/composeables/useLocalizedCurrency';
const { productUrl } = useLinkReplacer();

const uiStore = useUiStore();
const cartStore = useCartStore();
const { $toNumber } = useNuxtApp();

defineProps<{
  scrollPos: number;
  isScrolled: boolean;
}>();

const boughtProduct = ref<ProductRaw | ProductVariantOld | null>(null);
const userStore = useUserStore();

onMounted(async()=> {
  boughtProduct.value = cartStore.currentlyBuying;
});

onBeforeUnmount(()=> {
  cartStore.setAddedToCartCount(0);
});

const userPrice = computed(() => {
  return userStore.getPrice(cartStore.currentlyBuying?.partNo || '');
});

const priceByQuantity = computed(() => {
  return {
    price: $toNumber(userPrice.value?.price?.priceBeforeVat || '0') * cartStore.currentlyBuyingQuantity,
    priceBeforeDiscount: $toNumber(userPrice.value?.price?.priceBeforeDiscountBeforeVat || '0') * cartStore.currentlyBuyingQuantity,
  };
});
</script>

<style scoped lang="postcss">
</style>
