<template>
  <div class="px-24 flex justify-between w-full items-center mt-8">
    <TextInput
      v-model="loadId"
      :label="$t('loadCart.placeholder')"
      class="mb-18 basis-2/3"
      :errors="[ $t('loadCart.validation')]"
      :show-error="!valid"
      @enter="loadCart"
    />
    <button
      class="btn basis-1/3 relative -top-4"
      :disabled="loadId === ''"
      :class="{
        loading
      }"
      @click="loadCart"
    >
      {{ $t('loadCart.submit') }}
    </button>
  </div>
</template>

<script setup lang="ts">
import TextInput from '~/components/form-elements/TextInput.vue';
import useApiFetch from '~/composeables/useApiFetch';
import { useCartStore } from '~/store/cart';
import { useUiStore } from '~/store/ui';
const cartStore = useCartStore();

const uiStore = useUiStore();

const { apiGet, handleApiError } = useApiFetch();

const loadId = ref('');
const loading = ref(false);

const loadCart = async() => {
  if (loading.value) {
    return;
  }
  if (loadId.value.trim() !== '') {
    loading.value = true;
    const res = await apiGet<string>(`/basket/norce/${loadId.value}`);

    if (res) {
      const cartId = useCookie('cartId', { maxAge: 3600 * 24 * 182, path: '/' + useRoute().path?.split('/').filter((str) => str.length).shift() });
      cartId.value = res;

      setTimeout(async()=> {
        await cartStore.getCart(false);
        uiStore.closeAllModal();
      });
      loading.value = false;
    } else {
      handleApiError();
      loading.value = false;
    }
  }
};

const valid = computed(()=> {
  if (loadId.value.trim() === '') {
    return true;
  }
  const regex = new RegExp('^[0-9]+$');
  return regex.test(loadId.value);
});

</script>

<style scoped lang="postcss">
</style>
